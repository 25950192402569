import { createRouter, createWebHistory } from 'vue-router'

import CoachesListVue from './pages/coaches/CoachesList.vue'
import NotFoundVue from './pages/NotFound.vue'
import store from './store/index'

const CoachDetailVue = () => import('./pages/coaches/CoachDetail.vue')

const CoachRegistrationVue = () =>
  import('./pages/coaches/CoachRegistration.vue')

const ContactCoachVue = () => import('./pages/requests/ContactCoach.vue')

const RequestsReceivedVue = () =>
  import('./pages/requests/RequestsReceived.vue')

const UserAuthVue = () => import('./pages/auth/UserAuth.vue')

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/coaches' },
    { path: '/coaches', component: CoachesListVue },
    {
      path: '/coaches/:id',
      component: CoachDetailVue,
      props: true,
      children: [{ path: 'contact', component: ContactCoachVue }],
    },
    {
      path: '/register',
      component: CoachRegistrationVue,
      meta: { requiresAuth: true },
    },
    {
      path: '/requests',
      component: RequestsReceivedVue,
      meta: { requiresAuth: true },
    },
    {
      path: '/auth',
      component: UserAuthVue,
      meta: {
        requiresUnauth: true,
      },
    },
    { path: '/:notFound(.*)', component: NotFoundVue },
  ],
})

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/auth')
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/coaches')
  } else {
    next()
  }
})

export default router
