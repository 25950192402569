export default {
  coaches(state) {
    return state.coaches
  },
  hasCoaches(state) {
    return state.coaches && state.coaches.length > 0
  },
  isCoach(_, getters, _2, rootGetters) {
    const coaches = getters.coaches
    const userId = rootGetters.userId
    return coaches.some((coach) => coach.id === userId)
  },
  shouldUpdate(state) {
    const lastFetched = state.lastFetched
    if (!lastFetched) {
      return true
    }
    const currentTimeStamp = new Date().getTime()
    return (currentTimeStamp - lastFetched) / 1000 > 60
  },
}
