<template>
  <section>
    <base-card>
      <h2>Page Not Found</h2>
      <p>
        Page could not be found. Maybe check out all our
        <router-link to="/coaches">coaches</router-link>
      </p>
    </base-card>
  </section>
</template>
