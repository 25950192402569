export default {
  async registerCoach(context, data) {
    const userId = context.rootGetters.userId
    const coachData = {
      firstName: data.first,
      lastName: data.last,
      description: data.desc,
      hourlyRate: data.rate,
      areas: data.areas,
    }

    const token = context.rootGetters.token

    const response = await fetch(
      `https://udemy-find-a-job-default-rtdb.firebaseio.com/coaches/${userId}.json?auth=${token}`,
      {
        method: 'PUT',
        body: JSON.stringify(coachData),
      }
    )

    const responseData = await response.json()

    if (!responseData.ok) {
      console.log(responseData)
    }
    context.commit('registerCoach', { ...coachData, id: userId })
  },
  async loadCoaches(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return
    }
    const response = await fetch(
      `https://udemy-find-a-job-default-rtdb.firebaseio.com/coaches.json`
    )

    const responseData = await response.json()

    if (!response.ok) {
      throw new Error(responseData.message || 'Failed to fetch')
    }

    const coaches = []

    for (const key in responseData) {
      coaches.push({ ...responseData[key], id: key })
    }

    context.commit('setCoaches', coaches)
    context.commit('setFetchedTimestamp')
  },
}
