export default {
  async contactCoach(context, payload) {
    const newRequest = {
      userEmail: payload.email,
      message: payload.message,
    }

    const response = await fetch(
      `https://udemy-find-a-job-default-rtdb.firebaseio.com/requests/${payload.coachId}.json/`,
      {
        method: 'POST',
        body: JSON.stringify(newRequest),
      }
    )

    const responseData = await response.json()

    if (!response.ok) {
      throw new Error(responseData.message || 'Error fetching')
    }

    newRequest.id = responseData.name
    newRequest.coachId = payload.coachId

    context.commit('addRequest', newRequest)
  },
  async fetchRequests(context) {
    const coachId = context.rootGetters.userId
    const token = context.rootGetters.token
    const response = await fetch(
      `https://udemy-find-a-job-default-rtdb.firebaseio.com/requests/${coachId}.json?=auth=${token}`
    )
    const responseData = await response.json()

    if (!response.ok) {
      throw new Error(responseData.message || 'Error fetching')
    }

    const requests = []

    for (const key in responseData) {
      requests.push({ ...responseData[key], coachId: coachId })
    }

    context.commit('setRequests', requests)
  },
}
