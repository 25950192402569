import { createApp, defineAsyncComponent } from 'vue'
import AppVue from './App.vue'
import BaseBadgeVue from './components/ui/BaseBadge.vue'
import BaseButtonVue from './components/ui/BaseButton.vue'
import BaseCardVue from './components/ui/BaseCard.vue'
import BaseSpinnerVue from './components/ui/BaseSpinner.vue'
import router from './router'
import store from './store'

const BaseDialog = defineAsyncComponent(() =>
  import('./components/ui/BaseDialog.vue')
)

const app = createApp(AppVue)

app.use(router)
app.use(store)

app.component('base-card', BaseCardVue)
app.component('base-button', BaseButtonVue)
app.component('base-badge', BaseBadgeVue)
app.component('base-spinner', BaseSpinnerVue)
app.component('base-dialog', BaseDialog)

app.mount('#app')
